.form-title {
	margin-bottom: 0.8rem;
	font-size: 2.4rem;
	line-height: 2.9rem;
	color: white;
	text-transform: none;

	@include media-breakpoint-up(md) {
		font-size: 2.8rem;
		line-height: 3.4rem;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 1.5rem;
		font-size: 3.2rem;
		line-height: 3.8rem;
	}
}

.form-description {
	margin-bottom: 0;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.2rem;
	color: white;

	@include media-breakpoint-up(md) {
		font-size: 1.8rem;
		line-height: 2.5rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 2rem;
		line-height: 2.7rem;
	}
}

.form-control {
	font-size: 1.4rem;
	line-height: 2rem;
	height: 100%;

	@include media-breakpoint-up(md) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
}

.form-control-lg {
	padding: 1.5rem 3.2rem;

	@include media-breakpoint-up(md) {
		padding-left: 2.6rem;
		padding-right: 2.6rem;
	}
}

textarea.form-control-textarea {
	min-height: 14.2rem;
}

.fields-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1.3rem;
}

.invalid-feedback,
.valid-feedback,
.invalid-tooltip {
	font-size: 1.2rem;
}

.selling-form {
	display: flex;
	flex-direction: column;
	gap: 3.5rem;
	padding: 5rem 1.8rem 6rem;
	border-radius: 1rem;
	background-color: #323331;

	@include media-breakpoint-up(md) {
		padding: 7rem 3rem;
	}

	@include media-breakpoint-up(lg) {
		padding: 7rem 4rem 9rem;
	}

	.btn {
		width: 100%;
		margin-top: 2rem;

		@include media-breakpoint-up(md) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}
}
