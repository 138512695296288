.logo {
	display: flex;
	align-items: center;
	gap: 0.6rem;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 2rem;
	color: white;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		color: var(--main-color);
	}

	@include media-breakpoint-up(md) {
		font-size: 1.5rem;
	}
}

.logo-img {
	width: 3.8rem;
	height: 4rem;

	@include media-breakpoint-up(md) {
		width: 5.1rem;
		height: 5.4rem;
	}
}
