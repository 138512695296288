// Override Bootstrap's Sass default variables

// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// Toggle global options
//$enable-gradients: true;
//$enable-shadows: true;

//$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
//$body-color: #333;
//$body-bg: #fff;
//$border-radius: .4rem;
//$success: #7952b3;

:root {
	--bs-body-font-family: Manrope, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
		sans-serif;
	--main-color: #efb35a;
	--main-color-darken: #d99b35;
	--section-bg: rgba(245, 245, 245, 1);
	font-size: 10px;
}

.container {
	--bs-gutter-x: 6rem;

	@include media-breakpoint-up(sm) {
		max-width: 688px;
	}

	@include media-breakpoint-up(lg) {
		max-width: 1120px;
	}

	@include media-breakpoint-up(xxl) {
		max-width: 1120px;
	}
}

.invalid-tooltip {
	font-size: 1rem;
}
