.section {
	padding-top: 6.5rem;
	padding-bottom: 6.5rem;

	@include media-breakpoint-up(md) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 13rem;
		padding-bottom: 13rem;
	}
}

.section-header {
	margin-bottom: 2.4rem;
	//margin-bottom: 3.3rem; // отступы с главной страницы отличаются от отступов в таких же элементах на остальных

	@include media-breakpoint-up(md) {
		margin-bottom: 3.6rem;
		//margin-bottom: 5rem;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 6rem;
		//margin-bottom: 7.3rem;
	}
}
