.footer {
	padding-top: 3.7rem;
	margin-top: auto;
	background-color: #323331;
}

.footer-link,
.footer-title {
	font-family:
		Inter,
		system-ui,
		-apple-system,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		sans-serif;
}

.footer-title {
	margin-bottom: 1.6rem;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 2.4rem;
	text-transform: uppercase;
	color: white;

	@include media-breakpoint-up(lg) {
		margin-bottom: 1rem;
		font-size: 18px;
		line-height: 24px;
	}
}

.footer-link {
	margin-bottom: 0;
	text-decoration: none;
	color: white;
	font-size: 1.2rem;
	line-height: 1.6rem;
	font-weight: 400;

	@include media-breakpoint-up(lg) {
		font-size: 1.4rem;
		line-height: 2.35rem;
	}
}

.footer-link:is(a) {
	&:hover,
	&:active {
		text-decoration: underline;
		color: var(--main-color);
	}

	&.active {
		color: var(--main-color);
	}
}

.footer-grid {
	padding-bottom: 3.8rem;

	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-columns: repeat(9, 1fr);
		grid-row-gap: 2rem;
		grid-column-gap: 6rem;
		grid-template-areas:
			"logo logo logo logo logo logo logo logo logo "
			"menu menu menu menu contacts contacts contacts contacts contacts"
			"address address address address address social social social social";
		padding-bottom: 2.8rem;
	}

	@include media-breakpoint-up(lg) {
		grid-column-gap: 3rem;
		grid-row-gap: 0;
		grid-template-columns: [logo] 248px [menu] 253px [contacts] 197px [address] 181px;
		grid-template-areas:
			"logo . . . "
			"logo menu contacts address"
			"social menu contacts address"
			"social menu contacts address";
		padding-bottom: 4.5rem;
		border-bottom: 0.3px solid white;
	}
	@include media-breakpoint-up(xl) {
		grid-column-gap: 6rem;
	}
}

.footer-section {
	& + & {
		margin-top: 2.8rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
}

.footer-section--menu {
	grid-area: menu;
}

.footer-section--contacts {
	grid-area: contacts;
}

.footer-section--address {
	grid-area: address;
}

.footer-section--social {
	grid-area: social;
}

.footer-logo {
	grid-area: logo;
	font-size: 1.5rem;
	line-height: 2rem;
	margin-bottom: 2.2rem;

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}

	.logo-img {
		width: 7.2rem;
		height: 7.6rem;
	}
}

.footer-nav {
	display: flex;
	column-gap: 9rem;

	@include media-breakpoint-up(md) {
		column-gap: 3.5rem;
	}
}

.footer-nav-col {
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
	margin: 0;

	@include media-breakpoint-up(lg) {
		gap: 1.5rem;
	}
}

.footer-nav-col--contacts {
	@include media-breakpoint-up(md) {
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 350px;
		gap: 1.4rem 2.6rem;

		.footer-link {
			display: block;

			&:first-child {
				order: 1;
			}

			&:nth-child(2) {
				order: 3;
			}

			&:nth-child(3) {
				order: 2;
			}

			&:last-child {
				order: 4;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		flex-direction: column;
		gap: 0.3rem;

		.footer-link {
			order: unset !important;
		}
	}
}

.footer-nav-col--address {
	@include media-breakpoint-up(lg) {
		gap: 0.7rem;
	}
}

.social-links {
	display: flex;
	gap: 2rem;

	@include media-breakpoint-up(md) {
		justify-content: flex-end;
		max-width: 350px;
	}

	@include media-breakpoint-up(lg) {
		justify-content: flex-start;
		max-width: none;
		gap: 0.7rem;
	}

	a {
		text-decoration: none;
	}
}

.footer-copyright {
	display: flex;
	flex-direction: column;
	gap: 1.7rem 2.5rem;
	padding-bottom: 4.4rem;
	letter-spacing: 0.01rem;

	.footer-link:nth-child(2) {
		order: 2;

		@include media-breakpoint-up(md) {
			order: initial;
		}

		@include media-breakpoint-up(lg) {
			order: -1;
		}
	}

	.footer-link:is(a) {
		text-decoration: underline;
	}

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 3.3rem;
		padding-bottom: 3.3rem;
	}
}

.footer-copyright--img {
	order: 1;
	max-width: 20rem;

	@include media-breakpoint-up(md) {
		display: block;
		width: 17.5rem;
		height: 1.7rem;
		order: initial;
	}

	@include media-breakpoint-up(lg) {
		margin-left: auto;
		order: 3;
	}
}
