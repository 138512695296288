.categories {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
	grid-auto-rows: 21rem;
	gap: 2rem;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 24.6rem;
	}

	@include media-breakpoint-up(lg) {
		gap: 3rem;
		grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
		grid-auto-rows: 30rem;
	}
}

.category-card {
	color: unset;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	margin-bottom: 0;
	border-radius: 1rem;
	background: rgba(252, 252, 252, 1);
	box-shadow: 0 4px 20px 0 rgba(6, 6, 6, 0.15);
	transform: translateY(0);
	transition: 150ms transform;

	&:hover {
		transform: translateY(-4px);
	}
}

.category-card-header {
	z-index: 1;
	position: relative;
	padding: 1.3rem 2.5rem;
	border-radius: 1rem;
	background-color: white;

	@include media-breakpoint-up(md) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.category-card-title {
	display: flex;
	gap: 1.2rem;
	align-items: baseline;
	text-transform: none;
	font-weight: 400;

	&:after {
		display: block;
		width: 1.3rem;
		height: 1.6rem;
		content: url("../assets/icons/arrow-icon.svg");
	}
}

.category-card-img-wrapper {
	position: absolute;
	inset: 0;
}
.category-card-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
