@import "logo";

.navbar {
	--bs-navbar-toggler-icon-bg: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSIyMiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wIDEuOGgzM00wIDIwLjJoMzNNMCAxMWgzMyIvPjwvc3ZnPg==");
	--bs-navbar-nav-link-padding-x: 0;
	--bs-nav-link-font-size: 14px;
	--bs-navbar-brand-font-size: 15px;
	--bs-navbar-brand-margin-end: calc(50px - var(--bs-navbar-nav-link-padding-x));
	--bs-navbar-brand-padding-y: 0;
	--bs-navbar-padding-y: 1.4rem;

	@include media-breakpoint-up(sm) {
		--bs-navbar-padding-y: 2.6rem;
	}

	.dropdown-menu {
		margin-top: 1rem;
		background-color: #191919;
	}

	.dropdown-item {
		color: white;
		font-size: 1.4rem;
		line-height: 2.4rem;
		text-decoration: none;
		padding: 1rem 1.8rem;

		&:hover {
			background-color: rgba(50, 51, 49, 1);
		}

		&:active {
			background-color: var(--main-color);
			color: #222;
		}
	}
}

.navbar-nav {
	--bs-nav-link-padding-y: 0;
	--bs-nav-link-padding-x: 0;
	gap: 1rem;

	@include media-breakpoint-up(md) {
		gap: 2rem;
	}

	@include media-breakpoint-up(lg) {
		gap: 2.7rem;
	}
}

.navbar-collapse {
	order: 4;

	@include media-breakpoint-up(lg) {
		order: initial;
		flex-grow: 0;
		margin-left: auto;
	}

	.navbar-nav {
		padding: 2rem 0;

		@include media-breakpoint-up(lg) {
			padding: 0;
		}
	}
}

.header-link {
	padding: 0;
}

.navbar-toggler {
	border: none;
	padding: 0;
	width: 2.5rem;
	height: 1.6rem;

	@include media-breakpoint-up(md) {
		width: 3.3rem;
		height: 2rem;
	}

	&:focus {
		border: none;
	}
}

.navbar-toggler-icon {
	width: 100%;
	height: 100%;
}

.navbar-brand {
	margin-right: 0;
}

.header {
	background-color: rgba(25, 25, 25, 1);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	.container {
		row-gap: 6px;
	}

	@include media-breakpoint-up(sm) {
		color: black;
	}
}

.header .link:is(:focus, .active, .show, :hover) {
	color: var(--main-color);
}

.header-controls {
	display: flex;
	align-items: center;
	gap: 1.8rem;
	margin-left: 3rem;
}

.header-contacts {
	order: 3;

	@include media-breakpoint-up(sm) {
		order: initial;
		margin-left: auto;
	}
}

.header-additional-links {
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 8px;

	a {
		display: block;
	}
}

.header-contacts-item {
	display: flex;
	justify-content: space-between;
	gap: 12px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 1.4rem;
	line-height: 2.2rem;

	a {
		color: var(--main-color);
		text-decoration: none;
	}
}
