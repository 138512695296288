.heading {
	font-family:
		Oswald,
		system-ui,
		-apple-system,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
}

.heading-1 {
	font-size: 2.4rem;
	line-height: 3.2rem;

	@include media-breakpoint-up(md) {
		font-size: 4rem;
		line-height: 5.2rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 5.4rem;
		line-height: 7rem;
	}
}

.heading-2 {
	font-size: 2.4rem;
	line-height: 3.2rem;

	@include media-breakpoint-up(md) {
		font-size: 3.6rem;
		line-height: 4.6rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 4.6rem;
		line-height: 6rem;
	}
}

.heading-3 {
	font-size: 2rem;
	line-height: 2.6rem;

	@include media-breakpoint-up(md) {
		font-size: 2.2rem;
		line-height: 2.8rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 2.4rem;
		line-height: 2.8rem;
	}
}

.heading-emphasis {
	color: var(--main-color);
}

.emphasis {
	font-family: Caveat, sans-serif;
	text-transform: initial;
	font-weight: initial;
}

.paragraph {
	margin-bottom: 0;

	& + & {
		margin-top: 0.6rem;

		@include media-breakpoint-up(md) {
			margin-top: 1.6rem;
		}
	}
}

.paragraph--primary {
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 2.5rem;
	color: #202020;

	@include media-breakpoint-up(md) {
		font-size: 2rem;
		line-height: 2.9rem;

		& + & {
			margin-top: 2rem;
		}
	}
}

.paragraph--secondary {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.32rem;
	color: rgba(77, 77, 77, 1);

	@include media-breakpoint-up(lg) {
		font-size: 1.8rem;
		line-height: 2.6rem;
	}
}

.section-small-heading {
	font-size: 2.4rem;
	font-weight: 600;
	line-height: 3.5rem;

	@include media-breakpoint-up(md) {
		font-size: 2.6rem;
		line-height: 3.8rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 3.2rem;
		line-height: 4.6rem;
	}
}
