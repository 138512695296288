.products {
	--grid-gap: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
	grid-auto-rows: minmax(21rem, min-content);
	gap: var(--grid-gap);

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: minmax(24.6rem, min-content);
	}

	@include media-breakpoint-up(lg) {
		--grid-gap: 3rem;
		grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
		grid-auto-rows: minmax(30rem, min-content);
	}
}

.product-card {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	margin: 0;
	background-color: var(--section-bg);
	box-shadow: 0 2px 30px 0 rgba(6, 6, 6, 0.05);
}

.product-card-notes {
	position: absolute;
	top: 5px;
	right: 5px;
	left: 5px;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.product-card-notes-icon {
	width: 4.8rem;
	height: 4.8rem;
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.product-card-image-wrapper {
	border: 1px solid rgba(200, 201, 202, 1);
	border-radius: 1rem;
	overflow: hidden;
}

.product-card-image {
	width: 100%;
	height: 220px;
	object-fit: cover;
	display: block;
	background: white;
}

.product-card-header {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 2.24rem;
}

.product-card-title {
	font-size: 1.6rem;
	line-height: 2.24rem;
	font-weight: 400;
	color: rgba(32, 32, 32, 1);
}

.product-card-title,
.product-card-description,
.product-card-price {
	margin: 0;
}

.product-card-description {
	color: rgba(6, 6, 6, 0.6);
}

.product-card-price {
	color: rgba(32, 32, 32, 1);
	font-size: 18px;
	line-height: 32px;
}

.product-card-actions {
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem 3rem;
	gap: 0.8rem;
	margin-top: auto;

	@include media-breakpoint-up(md) {
		padding: 0.5rem 2rem 3rem;
	}

	@include media-breakpoint-up(lg) {
		padding: 1rem 2rem 3.8rem;
	}

	.btn {
		flex-grow: 1;
	}
}
