@import "bootstrap/scss/functions";
@import "bootstrap/scss/buttons";

.btn {
	--bs-btn-font-size: 1.4rem;
	--bs-btn-font-weight: 400;
	--btn-line-height: 2.2rem;
	--bs-btn-padding-y: 1.4rem;
	--bs-btn-padding-x: 1.2rem;
	--bs-btn-border-radius: 5rem;
}

.btn-primary {
	--bs-btn-color: white;
	--bs-btn-bg: var(--main-color);
	--bs-btn-border-color: var(--main-color);
	--bs-btn-hover-border-color: var(--main-color-darken);
	--bs-btn-hover-bg: var(--main-color-darken);
	--bs-btn-active-bg: var(--main-color-darken);
	--bs-btn-active-border-color: var(--main-color-darken);
}

.btn-secondary {
	--bs-btn-color: var(--main-color);
	--bs-btn-bg: white;
	--bs-btn-border-color: white;
	--bs-btn-hover-border-color: #f9f9f9;
	--bs-btn-hover-bg: #f9f9f9;
	--bs-btn-active-bg: #f9f9f9;
	--bs-btn-hover-color: var(--main-color);
	--bs-btn-active-border-color: #f9f9f9;
	--bs-btn-active-color: var(--main-color);
}

.btn-sm {
	--bs-btn-padding-y: 0.8rem;
	--bs-btn-padding-x: 0.8rem;
	--bs-btn-font-size: 1.4rem;
	--bs-btn-line-height: 2.2rem;
}

.btn-link {
	--bs-btn-color: rgba(32, 32, 32, 1);
	--bs-btn-hover-color: var(--main-color);
	--bs-btn-padding-x: 0;
	--bs-btn-padding-y: 0;
}
