.link {
	color: white;
	white-space: nowrap;
	text-decoration: none;
	font-size: 1.4rem;
	line-height: 2.4rem;

	&:hover,
	&:active {
		text-decoration: underline;
		color: var(--main-color);
	}
}
