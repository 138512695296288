.sijeko-footer {
	padding: 1rem;
	margin-top: 2rem;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	text-align: center;
	opacity: 0.2;
	transition: opacity 0.4s;

	&:hover {
		opacity: 1;
	}

	em {
		color: #ff7000;
		font-style: normal;
	}
}

:root {
	height: 100%;
	-webkit-font-smoothing: antialiased;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
